import IntlPhoneInput from "@components/intl-phone-input";
import { checkStatus, parseJSON } from "@utils/fetch";
import { trackConversion } from "@utils/tracking";
import { validateWebsite } from "@utils/validations";
import { validateBusinessEmail } from "@utils/validations";
import classNames from "classnames";
import React, { useState } from "react";
import { useSnackbar } from "react-simple-snackbar";
import { SourceInformation } from "./helpers/Cookies";
import { invokeWhatsApp, sendTrackingEmail } from "./WANumberForm";

interface WAdemoFormProps {
    subjectSuffix?: string;
    ctaLabel?: string | null;
    classes?: string;
    styles?: React.CSSProperties;
    onSubmit?: () => void;
    modalHeader?: string | null;
    onClose?: () => void;
    tracker?: SourceInformation | undefined;
}

const WAdemoForm: React.FC<WAdemoFormProps> = (props) => {
    const { classes, onClose, styles, subjectSuffix, ctaLabel, modalHeader, tracker } = props;

    const [website, setWebsite] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [mobile, setMobile] = useState<string>("");

    const [bemail, setBemail] = useState<string>("");

    const [vWebsite, setVwebsite] = useState<boolean>(true);
    const [vname, setVName] = useState<boolean>(true);
    const [vmobile, setVMobile] = useState<boolean>(true);
    const [vBEmail, setVBEmail] = useState<boolean>(true);

    const [isLoading, setLoading] = useState(false);
    const [country, setCountry] = useState();
    const [openSuccessSnackbar] = useSnackbar({
        style: {
            backgroundColor: "#10B981",
            zIndex: "1000",
        },
    });
    const [openErrorSnackbar] = useSnackbar({
        style: {
            backgroundColor: "#DC2626",
            zIndex: "1000",
        },
    });

    const resetValidations = () => {
        setVName(true);
        setVwebsite(true);
        setVMobile(true);
    };

    const reset = () => {
        setName("");
        setWebsite("");
        setMobile("");
        resetValidations();
    };

    const onNameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setName(event.target.value);
    };

    const onWebsiteChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setWebsite(event.target.value);
    };
    const onMobileChange = (value: string) => {
        setMobile(value);
    };

    const onBEmailChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setBemail(event.target.value);
    };

    React.useEffect(() => {
        fetch("https://www.iplocate.io/api/lookup/json")
            .then(checkStatus)
            .then(parseJSON)
            .then((res) => {
                setCountry(res.country_code);
            })
            .catch(console.log);
    }, []);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();
        resetValidations();
        if (isLoading) return;

        if (!name.trim()) {
            setVName(false);
            openErrorSnackbar("It seems you haven't typed your name yet. Please type your name.", 9000);
            return;
        }

        if (!validateWebsite(website)) {
            setVwebsite(false);
            openErrorSnackbar("Please enter your valid website url.", 9000);
            return;
        }
        if (!validateBusinessEmail(bemail)) {
            setVBEmail(false);
            openErrorSnackbar("Please enter your valid work e-mail Id.", 9000);
            return;
        }

        trackConversion();
        setLoading(true);
        const msg = `Hello Gallabox team. I am ${name}, looking for WhatsApp Business Solution demo and you can contact me at ${mobile} `;
        invokeWhatsApp(msg);
        // invokeCalendly({ name, businessEmail: email, mobile });
        try {
            await sendTrackingEmail({
                email: bemail,
                name,
                mobile,
                website,
                subjectSuffix: subjectSuffix ?? "WhatsApp Solutions [Bottom CTA]",
                ctaLabel,
                tracker,
            }).then((res) => {
                reset();
                const { responseText = "Thanks for your interest, we will contact you soon." } = res;
                openSuccessSnackbar(responseText, 9000);
            });
            props.onSubmit?.();
        } catch (error) {
            openErrorSnackbar("OOPS! Something went wrong, please send your message to team@gallabox.com", 10000);
        } finally {
            setLoading(false);
            if (onClose) onClose();
        }
    };

    return (
        <form className={classNames(classes)} style={styles} onSubmit={onSubmit}>
            <h5 className="m-0 text-2xl font-medium flex justify-center">{modalHeader}</h5>
            <div className="flex flex-col">
                <label className="font-bold text-base">Your Name</label>
                <input
                    value={name}
                    onChange={onNameChange}
                    disabled={isLoading}
                    className={classNames("h-12 rounded-lg border-solid border border-gray-200 text-base px-2", { "border-red-500 bg-red-100 border-4": !vname })}
                    placeholder="Enter Your Name"
                />
            </div>
            <div className="flex flex-col">
                <label className="font-bold text-base">WhatsApp Number</label>
                {/* <input
                    value={mobile}
                    onChange={onMobileChange}
                    disabled={isLoading}
                    className={classNames("h-12 rounded-lg border-solid border border-gray-200 text-base px-2", { "border-red-500 bg-red-50 border-2": !vmobile })}
                    placeholder="Enter Your WhatsApp Number"
                /> */}
                <IntlPhoneInput
                    country={country ?? "IN"}
                    placeholder="Enter Your WhatsApp Number"
                    className={classNames("text-base md:w-full p-3 pl-16 h-12  rounded-lg border border-solid border-gray-200 text-left", {
                        "border-red-500 bg-red-50 border-2": !vmobile,
                    })}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onBlur={() => {}}
                    onChange={onMobileChange}
                    disabled={isLoading}
                />
            </div>
            <div className="flex flex-col">
                <label className="font-bold text-base">Company Website</label>
                <input
                    value={website}
                    onChange={onWebsiteChange}
                    disabled={isLoading}
                    className={classNames("h-12 rounded-lg border-solid border border-gray-200 text-base px-2", { "border-red-500 bg-red-50 border-2": !vWebsite })}
                    placeholder="Enter Your Company Website"
                />
            </div>
            {/* <div className="flex flex-col">
                <label className="font-bold text-base">Work Email</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={isLoading}
                    className={classNames("h-12 rounded-lg border-solid border border-gray-200 text-base px-2", { "border-red-500 bg-red-50 border-2": !vWebsite })}
                    placeholder="Enter Your E-mail"
                    required
                />
            </div> */}

            <div className="flex flex-col">
                <label className="font-bold text-base">Work Email</label>
                <input
                    value={bemail}
                    onChange={onBEmailChange}
                    disabled={isLoading}
                    className={classNames("h-12 rounded-lg border-solid border border-gray-200 text-base px-2", { "border-red-500 bg-red-50 border-2": !vBEmail })}
                    placeholder="Enter Your Work E-mail ID"
                />
            </div>

            <button
                disabled={isLoading}
                type="submit"
                className={classNames("track_submit h-12 w-full rounded-lg text-white hover:bg-blue-800 bg-crayola-blue text-base font-semibold", {
                    "opacity-50": isLoading,
                    "opacity-100": !isLoading,
                })}
            >
                {isLoading ? "Submitting..." : ctaLabel}
            </button>
            <p className="text-sm">
                By Clicking the button you agree with our <a href="/terms">Terms and Policies</a>{" "}
            </p>
        </form>
    );
};

export default WAdemoForm;
