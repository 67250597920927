import { getUtmAndReferrer, SourceInformation } from "@components/helpers/Cookies";
import WAdemoForm from "@components/WAdemoForm";
import React, { FC, PropsWithChildren, useState } from "react";
import ReactDOM from "react-dom";
import { HiX } from "react-icons/hi";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ModalProps {
    isOpen?: boolean;
    ctaLabel?: string | null;
    onOpen?: () => void;
    onClose?: () => void;
    subjectSuffix?: string;
    showForm?: boolean;
    modalHeader?: string | null;
}

const GetStartedModal: FC<ModalProps> = (props) => {
    const { onClose, subjectSuffix, modalHeader } = props;
    const [campaignDetails, setCampaignDetails] = useState<SourceInformation | undefined>({});

    React.useEffect(() => {
        setCampaignDetails({ ...getUtmAndReferrer() });
    }, []);

    const isBrowser = typeof window !== "undefined";

    if (!isBrowser) return null;

    const handleClose: React.MouseEventHandler<HTMLButtonElement> = (evt) => {
        evt.preventDefault();
        onClose?.();
    };

    const handleSubmit = () => {
        onClose?.();
    };

    return (
        <Modal>
            <div className="fixed z-100 inset-0 overflow-y-hidden" aria-labelledby="modal-title" role="dialog" aria-modal="true" id="gb-modal">
                <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-4 text-center sm:p-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>

                    <div className=" lg:w-96 w-screen relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg">
                        <div className="absolute top-0 right-0 px-4 py-2 text-lg">
                            <button className="p-1 cursor-pointer text-gray-600 hover:bg-gray-200 rounded-md" onClick={handleClose}>
                                <HiX />
                            </button>
                        </div>
                        <div className="bg-white w-400px sm:max-h-screen px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <WAdemoForm
                                classes="flex w-full flex-col gap-2 bg-white mx-auto"
                                onSubmit={handleSubmit}
                                subjectSuffix={subjectSuffix}
                                ctaLabel={modalHeader}
                                onClose={onClose}
                                modalHeader={modalHeader}
                                tracker={campaignDetails}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export const Modal: FC<PropsWithChildren> = ({ children }) => {
    return ReactDOM.createPortal(children, document.getElementById("__next") as Element);
};

export default GetStartedModal;
