export const validateEmail = (email: string): boolean => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

export const validateBusinessEmail = (email: string): boolean => {
    const re = /^(?!.+@(gmail|yahoo|hotmail|yahoomail|aol|abc|xyz|pqr|rediffmail|live|outlook|me|msn|ymail)\..+)(.+@.+\..+)$/i;
    return re.test(email);
};

export const validateMobile = (mobile: string): boolean => {
    const re = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;
    return re.test(mobile);
};

export const validateWebsite = (website: string): boolean => {
    const re2 = /(google.com|abc.com|xyz.com)/g;
    const re = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-\\._~:/?#[\]@!$&'()*+,;=.]+$/;
    return re.test(website) && !re2.test(website);
};
