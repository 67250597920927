import useModalForm from "@hooks/useModalForm";
import cx from "classnames";
import Head from "next/head";
import React, { FC, PropsWithChildren, ReactElement, useEffect, useRef, useState } from "react";
import DesktopFooter from "./LandingPageComponents/DesktopFooter";
import MobileFooterSeparate from "./LandingPageComponents/MobileFooterSeparate";
import MobileNavBar from "./MobileNavBar";
import TopNavBlock from "./TopNavBlock";
import UnknownNavBar from "./UnknownNavBar";
import Image from "next/image";

export const openWhatsapp = (text: string): void => {
    const phone = 917825877730;
    const message = encodeURIComponent(text);
    window.open(`https://wa.me/${phone}?text=${message}`, "_blank");
};
export type FooterLinkProps = { href: string; "data-href": string; target?: string; onClick?: never } | { onClick: () => void; href?: never; "data-href"?: never; target?: never };

export const AppFooter: React.FC<{ noMarginTop?: boolean }> = ({ noMarginTop = false }) => {
    const { showForm, jsx } = useModalForm();

    const handleFooterLinkClick = (linkData: any) => {
        if (linkData?.externalLink) {
            window.open(linkData?.externalLink, "_blank");
        } else if (linkData?.link) {
            window.open(linkData?.link);
        } else if (linkData?.showForm) {
            showForm("Get Started");
        }
    };

    const handleFooterLinkV2 = (linkData: any): FooterLinkProps => {
        if (linkData?.externalLink) {
            return { href: linkData?.externalLink, "data-href": linkData?.externalLink, target: "_blank" };
        } else if (linkData?.link) {
            return { href: linkData?.link, "data-href": linkData?.link };
        } else if (linkData?.showForm) {
            return { onClick: () => showForm("Get Started") };
        }
        return { href: "", "data-href": "", target: undefined, onClick: undefined };
    };

    return (
        <>
            {jsx}
            <DesktopFooter noMarginTop={noMarginTop} handleFooterLinkV2={handleFooterLinkV2} />
            <MobileFooterSeparate handleFooterLinkClick={handleFooterLinkClick} />
        </>
    );
};

interface NavMenuProps {
    cx?: string;
    setIsNavbarOpen?: any;
    isKuwaitHeader?: any;
    isWhatsappPageNav?: any;
    menuHandler?: any;
    showBanner?: boolean;
    customNavBar?: boolean;
}

export const handleLogin = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();
    window.open("https://app.gallabox.com/login");
};

export const handleSignup = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();
    window.open("https://app.gallabox.com/signup");
};
// eslint-disable-next-line react/display-name
const NavMenu = React.forwardRef<HTMLDivElement, NavMenuProps>((props, ref) => {
    const { setIsNavbarOpen, isKuwaitHeader, isWhatsappPageNav, menuHandler, showBanner, customNavBar } = props;
    const [open, setOpen] = useState(false);

    useEffect(() => {
        menuHandler(open);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <div className={`${showBanner ? "lg:pb-10 pb-28" : "lg:pb-0 pb-14"}`}>
            <div ref={ref} className={cx("w-full bg-white left-0 z-10", props.cx)} style={{ boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)" }}>
                {showBanner && (
                    <div className="lg:block hidden h-20">
                        <TopNavBlock
                            titleContent="Instantly Craft Perfect WhatsApp Templates with AI Assistance✨"
                            btnText="Try now"
                            link="https://gallabox.com/ai-whatsapp-template-generator"
                            isIconBtn
                            customStyles={"sticky top-0"}
                        />
                    </div>
                )}
                <div className={`px-4 py-4 flex flex-col max-w-6xl mx-auto md:items-center ${!customNavBar ? "md:justify-between md:flex-row" : ""} w-full`}>
                    {!customNavBar && (
                        <div className="flex flex-row items-center justify-between">
                            <a href="/" className={"focus:outline-none focus:shadow-outline"} aria-label="Gallabox">
                                <img alt="Gallabox" src="/gallabox-logo.svg" className="mx-auto lg:mx-0 w-32" width="auto" height="auto" />
                            </a>
                            <button
                                className="md:hidden focus:outline-none focus:shadow-outline"
                                onClick={() => {
                                    setOpen((prev) => !prev);

                                    if (setIsNavbarOpen) {
                                        setIsNavbarOpen(!open);
                                    }
                                }}
                            >
                                <svg fill="currentColor" viewBox="0 0 20 20" className="w-5 h-5" style={{ width: "22.79px", height: "20.06px" }}>
                                    {!open && (
                                        <path
                                            d="M23.2106 0.168457H0.875855C0.750507 0.168457 0.647949 0.271014 0.647949 0.396362V2.2196C0.647949 2.34495 0.750507 2.44751 0.875855 2.44751H23.2106C23.3359 2.44751 23.4385 2.34495 23.4385 2.2196V0.396362C23.4385 0.271014 23.3359 0.168457 23.2106 0.168457ZM23.2106 17.9451H0.875855C0.750507 17.9451 0.647949 18.0476 0.647949 18.173V19.9962C0.647949 20.1216 0.750507 20.2241 0.875855 20.2241H23.2106C23.3359 20.2241 23.4385 20.1216 23.4385 19.9962V18.173C23.4385 18.0476 23.3359 17.9451 23.2106 17.9451ZM23.2106 9.05676H0.875855C0.750507 9.05676 0.647949 9.15932 0.647949 9.28467V11.1079C0.647949 11.2333 0.750507 11.3358 0.875855 11.3358H23.2106C23.3359 11.3358 23.4385 11.2333 23.4385 11.1079V9.28467C23.4385 9.15932 23.3359 9.05676 23.2106 9.05676Z"
                                            fill="#2D3748"
                                        />
                                    )}
                                    {open && (
                                        <path
                                            d="M20.5812 0.945068L0.24646 17.9451C0.121112 17.9451 0.0185547 18.0476 0.0185547 18.173V19.9962C0.0185547 20.1216 0.121112 20.2241 0.24646 20.2241L20.5812 3.22412C20.7065 3.22412 20.8091 3.12156 20.8091 2.99622V1.17297C20.8091 1.04763 20.7065 0.945068 20.5812 0.945068ZM20.5812 18.7217L0.24646 1.72168C0.121112 1.72168 0.0185547 1.82424 0.0185547 1.94959V3.77283C0.0185547 3.89818 0.121112 4.00074 0.24646 4.00074L20.5812 21.0007C20.7065 21.0007 20.8091 20.8982 20.8091 20.7728V18.9496C20.8091 18.8242 20.7065 18.7217 20.5812 18.7217Z"
                                            fill="#2D3748"
                                        />
                                    )}
                                </svg>
                            </button>
                        </div>
                    )}
                    {customNavBar && (
                        <div className="w-full flex justify-between">
                            <Image width={128} height={28} src="/gallabox-logo.svg" alt="Gallabox logo" />
                            <a
                                href="https://app.gallabox.com/signup"
                                target="_blank"
                                rel="noreferrer"
                                className="px-4 py-2 text-base font-medium text-white rounded-md hover:no-underline focus:outline-none hover:shadow-lg bg-blue-500 hover:bg-blue-600 transition duration-300 ease-in-out"
                            >
                                7 Day Free Trial
                            </a>
                        </div>
                    )}

                    {open ? (
                        <MobileNavBar handleLogin={handleLogin} isKuwaitHeader={isKuwaitHeader} isWhatsappPageNav={isWhatsappPageNav} customNavBar={customNavBar ?? false} />
                    ) : (
                        <UnknownNavBar
                            open={open}
                            handleLogin={handleLogin}
                            isKuwaitHeader={isKuwaitHeader}
                            isWhatsappPageNav={isWhatsappPageNav}
                            customNavBar={customNavBar ?? false}
                        />
                    )}
                </div>
                {showBanner && (
                    <div className="lg:hidden block">
                        <TopNavBlock
                            titleContent="Instantly Craft Perfect WhatsApp Templates with AI Assistance✨"
                            btnText="Try now"
                            link="https://gallabox.com/ai-whatsapp-template-generator"
                            isIconBtn
                            customStyles={"sticky top-0"}
                            isMobile={true}
                        />
                    </div>
                )}
                <style jsx>
                    {`
                        .start-trial-btn {
                            background-color: #0083ff;
                            border-radius: 6px;
                            font-weight: 500;
                            font-size: 16px;
                            color: #ffffff;
                            padding: 0.5em 1em;
                            min-width: fit-content;
                        }
                        .login-btn {
                            font-weight: 500;
                            font-size: 16px;
                            color: #4a5568;
                            cursor: pointer;
                        }
                        .login-btn:hover {
                            text-decoration: none;
                        }
                    `}
                </style>
            </div>
        </div>
    );
});

interface SocialCTAProps {
    name: string;
    brandColor: string;
    href: string;
    icon: ReactElement;
    gradient?: boolean;
    gradientBorder?: string;
}

export const SocialCTA: FC<SocialCTAProps> = (props) => {
    const { name, href, brandColor, icon, gradient = false, gradientBorder } = props;
    return (
        <a href={href} aria-label={name ?? "social"} target="_blank" rel="noreferrer" className="p-2 text-lg lg:text-base rounded-full transition ease-linear duration-100">
            {icon}
            <style jsx>{`
                color: #000;
                border: 1px solid rgba(0, 0, 0, 0.5);
                :hover {
                    color: #fff;
                    border: 1px solid ${gradient ? gradientBorder : brandColor};
                    background: ${brandColor};
                }
            `}</style>
        </a>
    );
};

interface NewLayoutProps {
    showMenu?: boolean;
    title: string;
    description: string;
    keywords?: string;
    withLiveChat?: boolean;
    ogAttributes?: {
        title?: string;
        siteName?: string;
        type?: string;
        description?: string;
        image?: string;
    };
    setIsNavbarOpen?: any;
    customFooter?: any;
    isKuwaitHeader?: boolean;
    isWhatsappPageNav?: boolean;
    noIndex?: boolean;
    disableNav?: boolean;
    canonical?: string;
    bgColor?: string;
    showBanner?: boolean;
    customNavBar?: boolean;
}

const NewLayout: FC<PropsWithChildren<NewLayoutProps>> = (props) => {
    const {
        children,
        showMenu = true,
        title,
        keywords,
        description,
        ogAttributes,
        setIsNavbarOpen = false,
        customFooter,
        isKuwaitHeader = false,
        isWhatsappPageNav = false,
        noIndex = false,
        disableNav = false,
        canonical,
        showBanner = false,
        customNavBar = false,
    } = props;

    const [menu, setMenu] = useState(false);

    const menuHandler = (state: boolean) => {
        // console.log(state);
        setMenu(state);
    };

    const pageRef = useRef<HTMLDivElement>(null);
    const scrollToTopButton = useRef<HTMLButtonElement>(null);
    const navRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const onScroll = () => {
            const buttonElement = scrollToTopButton.current;
            const navElement = navRef.current;
            if (!pageRef.current) return;
            if (buttonElement) {
                if (pageRef.current.scrollTop > window.innerHeight) {
                    buttonElement.style.display = "block";
                } else {
                    buttonElement.style.display = "none";
                }
            }
            if (navElement) {
                if (pageRef.current.scrollTop > 90) {
                    navElement.style.top = "0";
                } else {
                    navElement.style.top = "-200px";
                }
            }
        };

        const onScrollToTop = () => {
            pageRef.current?.scrollTo({ top: 0, behavior: "smooth" });
        };

        const buttonElement = scrollToTopButton.current;
        const pageElement = pageRef.current;
        buttonElement?.addEventListener("click", onScrollToTop);
        pageElement?.addEventListener("scroll", onScroll);
        return () => {
            pageElement?.removeEventListener("scroll", onScroll);
            buttonElement?.removeEventListener("click", onScrollToTop);
        };
    }, []);

    return (
        <div
            ref={pageRef}
            className={`${!menu ? "overflow-y-auto" : "overflow-y-hidden"} ${
                !disableNav && "whole-body-top-padding"
            } relative hide-scrollbar  w-screen max-h-screen  overflow-x-hidden font-gb-home-body`}
            style={{ scrollBehavior: "smooth" }}
        >
            <Head>
                <meta charSet="utf-8" />
                <title>{title}</title>
                <meta name="description" content={description} />
                {/* <link rel="canonical" href={canonical} /> */}
                <meta name="keywords" content={keywords} />
                <meta name="viewport" content="width=device-width, user-scalable=no" />
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="manifest" href="/site.webmanifest" />
                {/* <link rel="mask-icon" href="safari-pinned-tab.svg" color="#000000" /> */}
                <link rel="shortcut icon" href="/favicon.ico" />
                <meta name="msapplication-TileColor" content="#3364FB" />
                <meta name="theme-color" content="#3364FB" />

                <meta name="twitter:site" content="@gallabox" />
                <meta name="twitter:card" content="summary_large_image" />
                {/* <meta property="twitter:url" content={canonical} /> */}

                <meta property="og:title" content={ogAttributes?.title ?? title} />
                <meta property="og:site_name" content={ogAttributes?.siteName ?? "Gallabox"} />
                {/* <meta property="og:url" content={canonical} /> */}
                <meta property="og:type" content={ogAttributes?.type ?? "website"} />
                <meta property="og:description" content={ogAttributes?.description ?? description} />
                <meta property="og:image" content={ogAttributes?.image ?? "https://assets.gallabox.com/gb-home/preview-image.jpg"} />
                <meta property="og:image:url" content={ogAttributes?.image ?? "https://assets.gallabox.com/gb-home/preview-image.jpg"} />
                <meta property="og:image:secure_url" content={ogAttributes?.image ?? "https://assets.gallabox.com/gb-home/preview-image.jpg"} />
                <link rel="preload" href="/fonts/Google_Sans/GoogleSans-Bold.ttf" as="font" crossOrigin="" />
                <link rel="preload" href="/fonts/Google_Sans/GoogleSans-Medium.ttf" as="font" crossOrigin="" />
                <link rel="preload" href="/fonts/Google_Sans/GoogleSans-Regular.ttf" as="font" crossOrigin="" />
                {noIndex && <meta name="robots" content="noindex" />}
                {canonical && <link rel="canonical" href={canonical} />}
            </Head>
            <div className={`bg-white ${showBanner ? "lg:pt-8 pt-0" : ""} `}>
                {disableNav
                    ? null
                    : showMenu && (
                          <>
                              <NavMenu
                                  isKuwaitHeader={isKuwaitHeader}
                                  isWhatsappPageNav={isWhatsappPageNav}
                                  setIsNavbarOpen={setIsNavbarOpen}
                                  cx={"fixed top-0 "}
                                  menuHandler={menuHandler}
                                  showBanner={showBanner}
                                  customNavBar={customNavBar}
                              />
                          </>
                      )}
                {children}
                {customFooter ? customFooter : <AppFooter />}
            </div>

            <style jsx>{`
                ::-webkit-scrollbar {
                    width: 8px;
                }

                ::-webkit-scrollbar-track {
                    border-radius: 10px;
                }

                ::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    background-color: #00000050;
                }
            `}</style>
        </div>
    );
};

export default NewLayout;
