import Lottie from "lottie-react";
import React, { useRef } from "react";
import bannerAnimation from "../public/lottie/website-banner-animation.json";
interface styleInterface {
    customStyles?: string;
    btnText: string;
    titleContent: string;
    isIconBtn?: boolean;
    link: string;
    isMobile?: boolean;
}

const TopNavBlock: React.FC<styleInterface> = ({ customStyles, btnText, titleContent, link, isMobile = false }) => {
    const topBlk = useRef<HTMLDivElement>(null);

    return (
        <div ref={topBlk} className={`above-navbar px-2 z-10 w-full ${customStyles}`}>
            <div className="flex justify-center items-center lg:space-y-0 space-y-2">
                {isMobile ? (
                    <p className="py-3 text-sm font-semibold text-center text-white">
                        {titleContent}
                        <a
                            className="gb_website_banner_btn pl-2 text-sm font-semibold underline cursor-pointer"
                            style={{ color: "#25D366" }}
                            href={link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {btnText}
                        </a>
                    </p>
                ) : (
                    <div className="lg:block hidden">
                        <div className="relative flex items-center justify-center">
                            <div style={{ minWidth: "980px", maxHeight: "80px" }}>
                                <Lottie animationData={bannerAnimation} loop />
                            </div>
                            <div className="absolute max-w-6xl flex items-center justify-center gap-5 w-full">
                                <p className="text-xl font-semibold text-center text-white">{titleContent}</p>
                                <a
                                    className="gb_website_banner_btn banner_button text-base text-center font-semibold cursor-pointer text-white hover:no-underline px-5 py-2 rounded-full whitespace-nowrap"
                                    href={link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {btnText}
                                </a>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TopNavBlock;
