import { useState } from "react";
import { checkStatus, parseJSON, setPostHeaders } from "@utils/fetch";
import { useSnackbar } from "react-simple-snackbar";
import { validateBusinessEmail, validateEmail } from "@utils/validations";
import { trackConversion } from "@utils/tracking";
import classNames from "classnames";
import { openPopupWidget } from "react-calendly";
import { getUtmAndReferrer, SourceInformation } from "./helpers/Cookies";

const WA_API_HOST = "https://api.whatsapp.com";
const WA_API_ROUTE = "send";

const params = {
    phone: "917825877730",
    text: "Hello Gallabox team, please let me know more about your product.",
};

const getURL = (msg?: string) => {
    const q = new URLSearchParams();
    Object.entries(params).forEach((params) => q.set(...params));
    if (msg) {
        q.set("text", msg);
    }
    const queryParams = q.toString();
    return `${WA_API_HOST}/${WA_API_ROUTE}?${queryParams}`;
};

export const invokeCalendly = (
    {
        name = "",
        businessName = "",
        businessEmail = "",
        mobile = "",
    }: {
        name?: string;
        businessName?: string;
        businessEmail?: string;
        mobile?: string;
    },
    openInNewTab: boolean = true
): void => {
    const url = "https://calendly.com/gallabox/demo";
    if (window.Calendly && !openInNewTab) {
        const prefill = { name, email: businessEmail, customAnswers: { a1: businessName, a2: mobile } };
        openPopupWidget({ url, prefill });
    } else {
        const curl = `${url}?name=${name}&email=${businessEmail}&a1=${businessName}&a2=${mobile}`;
        trackConversion();
        window.open(curl, "_blank");
    }
};

export const invokeWhatsApp = (msg?: string): void => {
    const url = getURL(msg);
    trackConversion();
    window.open(url, "_blank");
};

interface TrackingEmailParams {
    email?: string;
    name: string;
    mobile: string;
    website?: string;
    businessName?: string;
    subjectSuffix: string;
    ctaLabel: string | null | undefined;
    attributes?: Record<string, string | number>;
    tracker?: SourceInformation;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const sendTrackingOnlyEmail = async (params: any): Promise<any> => {
    const { email, subjectSuffix, ctaLabel, tracker } = params;
    let utmTracker = {};
    try {
        utmTracker = getUtmAndReferrer();
    } catch (error) {
        console.log(error);
    }
    try {
        return await fetch(
            "/api/v1/requestDemo",
            setPostHeaders({
                email,
                subjectSuffix: subjectSuffix ?? "WhatsApp Solutions",
                track: { url: window.location.href ?? "", referrer: window.document.referrer ?? "", width: window.innerWidth ?? 0, ctaLabel: ctaLabel ?? "Nil" },
                tracker: tracker?.source ? tracker : utmTracker,
            })
        )
            .then(checkStatus)
            .then(parseJSON);
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const sendTrackingEmail = async (params: TrackingEmailParams): Promise<any> => {
    const { email, name, mobile, website, subjectSuffix, ctaLabel, tracker } = params;
    let utmTracker = {};
    try {
        utmTracker = getUtmAndReferrer();
    } catch (error) {
        console.log(error);
    }
    try {
        return await fetch(
            "/api/v1/requestDemo",
            setPostHeaders({
                email,
                name,
                mobile,
                subjectSuffix: subjectSuffix ?? "WhatsApp Solutions",
                track: { url: window.location.href ?? "", referrer: window.document.referrer ?? "", width: window.innerWidth ?? 0, ctaLabel: ctaLabel ?? "Nil" },
                attributes: { website },
                tracker: tracker?.source ? tracker : utmTracker,
            })
        )
            .then(checkStatus)
            .then(parseJSON);
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const WANumberForm: React.FC = () => {
    const ctaLabel = "Get Started";
    const [openErrorSnackbar] = useSnackbar({
        style: {
            backgroundColor: "#DC2626",
        },
    });

    const [businessEmail, setBusinessEmail] = useState<string>("");
    const [businessName, setBusinessName] = useState<string>("");
    const [validBusinessName, setValidBusinessName] = useState<boolean>(true);
    const [validBusinessEmail, setValidBusinessEmail] = useState<boolean>(true);

    const resetValidations = () => {
        setValidBusinessName(true);
        setValidBusinessEmail(true);
    };
    const reset = () => {
        setBusinessEmail("");
        setBusinessName("");
        resetValidations();
    };

    const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        resetValidations();

        const trimmedBNValue = businessName.trim();

        if (!trimmedBNValue) {
            setValidBusinessName(false);
            openErrorSnackbar("It seems you haven't typed your company name yet. Please type your company name.", 9000);
            return;
        }

        if (!validateEmail(businessEmail)) {
            setValidBusinessEmail(false);
            openErrorSnackbar("Your email address looks invalid. Please enter a valid address.", 9000);
            return;
        }
        if (!validateBusinessEmail(businessEmail)) {
            setValidBusinessEmail(false);
            openErrorSnackbar("Please enter your business email address.", 9000);
            return;
        }

        const msg = `Hello Gallabox team. I am looking for WhatsApp Business Solution for ${trimmedBNValue} and you can contact me at ${businessEmail}`;
        invokeWhatsApp(msg);
        /* invokeCalendly({
            businessName: trimmedBNValue,
            businessEmail,
        }); */
        sendTrackingEmail({
            email: businessEmail,
            name: trimmedBNValue,
            mobile: "",
            subjectSuffix: "WhatsApp Solutions [Hero CTA]",
            ctaLabel,
        });
        reset();
    };

    const onBusinessEmailChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setBusinessEmail(event.target.value);
    };
    const onBusinessNameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setBusinessName(event.target.value);
    };
    return (
        <form onSubmit={onSubmit} className="max-w-lg w-full flex flex-col lg:flex-row items-center gap-3 mt-4 mx-auto">
            <input
                type="text"
                required
                className={classNames("text-lg h-12 px-2 max-w-sm w-full lg:flex-1 text-center lg:text-left shadow rounded-lg", {
                    "border-red-500 bg-red-50 border-2": !validBusinessName,
                })}
                placeholder="Company Name"
                title=""
                onChange={onBusinessNameChange}
                value={businessName}
            />
            <input
                type="text"
                required
                className={classNames("text-lg h-12 px-2 max-w-sm w-full lg:flex-1 text-center lg:text-left shadow rounded-lg", {
                    "border-red-500 bg-red-50 border-2": !validBusinessEmail,
                })}
                placeholder="Company Email"
                title=""
                onChange={onBusinessEmailChange}
                value={businessEmail}
            />
            <button type="submit" className="track_herocta text-lg font-medium text-white bg-crayola-blue px-8 h-12 max-w-xs">
                {ctaLabel}
            </button>
        </form>
    );
};

export default WANumberForm;
