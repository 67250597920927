export interface SourceInformation {
    source?: string;
    medium?: string;
    name?: string;
    term?: string;
    content?: string;
    cu?: string;
    lu?: string;
    ru?: string;
}
const keyPrefix = "tracker_";
const name = "__utmz";

const getCookie = (name: string): string | null => {
    try {
        const ca = document.cookie.split(";");
        let value = null;
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === " ") {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(name) === 0) {
                value = c.substring(name.length, c.length);
                break;
            }
        }
        return value;
    } catch (e) {
        return null;
    }
};

export const getUtmAndReferrer = (): SourceInformation => {
    const utmRefererData = JSON.parse(getCookie(keyPrefix + name)?.substring(1) ?? "{}");
    return utmRefererData;
};
export default getCookie;
