import Countries from "./countries.json";

export interface Country {
    name: string;
    slug: string;
    iso: string;
    iso2: string;
    prefix: string;
    currency: string;
    region: string;
    subregion: string;
    latlng: number[];
}

const getCountryTelCode = (country?: string): string => {
    return (country && Countries.find(({ iso2 }) => iso2 === country)?.prefix) ?? "";
};

export { Countries, getCountryTelCode };
