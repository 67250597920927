import React, { FC } from "react";
import { HeadingAndColoredTextProps } from "@components/Typography/types";
import ColoredText from "@components/Typography/ColoredText";

const HeadingColoredAndNormal: FC<HeadingAndColoredTextProps> = ({ coloredText, normalText, customClass }) => {
    return (
        <div className={`app-heading-styles flex items-center justify-center ${customClass}`}>
            <h2 className="text-dark-blue mb-3.5 pb-1">
                <span className="text-4xl">{normalText} </span>
                <ColoredText coloredText={coloredText} tag="span" customClass="ml-2" fontSize={36} />
            </h2>
        </div>
    );
};

export default HeadingColoredAndNormal;
