import React, { FC } from "react";
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from "react-icons/hi";
import { ButtonWithArrowsProps } from "@components/Typography/types";

const ButtonWithArrow: FC<ButtonWithArrowsProps> = ({ buttonText, customClass, ga = true, onClickCTA, isArabic = false, arrow = false }) => {
    return (
        <button
            className={`${
                ga ? "jsx-1957620388" : ""
            } outline-none focus:outline-none w-full start-trial-btn md:w-auto  whitespace-nowrapp px-6 py-3 inline-flex items-center justify-center md:justify-start gap-1 transition duration-300 ease-in-out ${customClass}`}
            onClick={onClickCTA}
        >
            {arrow ? (
                <>
                    {isArabic && (
                        <span className="submit-btn-icon transition duration-300 ease-in-out">
                            <HiOutlineArrowNarrowLeft className="text-2xl" />
                        </span>
                    )}
                    <span className="text-sm tracking-wider font-medium button-text">{buttonText}</span>
                    {!isArabic && (
                        <span className="submit-btn-icon transition duration-300 ease-in-out">
                            <HiOutlineArrowNarrowRight className="text-2xl" />
                        </span>
                    )}
                </>
            ) : (
                <span className="text-sm tracking-wider font-medium button-text">{buttonText}</span>
            )}
        </button>
    );
};

export default ButtonWithArrow;
