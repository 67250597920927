import React from "react";

interface MobileNav {
    link: string;
    img: string;
    title: string;
    description: string;
    isNew?: boolean;
}

const MobileNavContent = (props: { item: MobileNav }) => {
    return (
        <div>
            <a href={props.item.link}>
                <div className="mob-nav-item" style={{ display: "flex" }}>
                    <div>
                        <img src={props.item.img} alt={`${props.item.title} icon`} />
                    </div>
                    <div>
                        <div className="title">
                            {props.item.title} {props.item.isNew && <span className="new-tag">New</span>}
                        </div>
                        <div className="description">{props.item.description}</div>
                    </div>
                </div>
            </a>
            <style jsx>{`
                .new-tag {
                    background-color: #25d366;
                    color: white;
                    font-size: 0.7em;
                    padding: 0.2em 0.5em;
                    border-radius: 3px;
                    margin-left: 5px;
                }
            `}</style>
        </div>
    );
};

export default React.memo(MobileNavContent);
