const trackLinkedInConversion = () => {
    const body = document.getElementsByTagName("body")[0];
    const img = document.createElement("img");
    img.height = 1;
    img.width = 1;
    img.style.display = "none";
    img.alt = "";
    img.src = "https://px.ads.linkedin.com/collect/?pid=2896204&conversionId=4275956&fmt=gif";
    body.appendChild(img);
};

export const trackConversion = (): void => {
    if (window.gtag) {
        window.gtag("event", "conversion", {
            send_to: "AW-451834661/7a_1CI__iO8BEKXmudcB",
        });
    }
    if (window.fbq) {
        window.fbq("track", "Lead");
    }
    trackLinkedInConversion();
};
