import React, { FC } from "react";
import { descriptionTextProps } from "@components/Typography/types";
const SubTitleText: FC<descriptionTextProps> = ({ tag = "p", coloredText, customClass }) => {
    const CustomTag = `${tag}`;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <CustomTag className={`app-sub-title-text ${customClass}`}>{coloredText}</CustomTag>;
};

export default SubTitleText;
