import React from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { AppButton } from "./Typography";

interface CtaButtonTypes {
    type: string;
    text?: string;
    onClick?: any;
    customClass?: string;
    fontWeight?: string;
    fontSize?: string;
    ga?: boolean;
    outlineArr?: boolean;
    href?: string;
}
const CtaButton: React.FC<CtaButtonTypes> = ({ type, text, customClass, fontSize, fontWeight, ga, outlineArr, href }) => {
    return (
        <>
            {type === "small" && (
                <AppButton
                    customClass={customClass}
                    buttonText={text || "Get Started"}
                    fontWeight={fontWeight}
                    fontSize={fontSize}
                    ga={ga}
                    href={href || "https://app.gallabox.com/signup"}
                    // className="transition   duration-600 text-base ease-in-out cursor-pointer rounded-full  py-3 lg:px-6 lg:py-2 bg-crayola-blue text-white hover:no-underline hover:shadow-lg"
                ></AppButton>
            )}
            {type === "medium" && (
                <a
                    className={`jsx-1957620388  main-button outline-none focus:ou start-trial-btn hover:no-underline mt-4 md:mt-8 hover:shadow-lg transition duration-300 ease-in-out inline-flex items-center justify-center md:justify-start gap-1 start-trial-btn font-bold text-lg ${customClass}`}
                    href={href || "https://app.gallabox.com/signup"}
                >
                    {" "}
                    {text || "Sign up for Free"}
                </a>
            )}
            {type === "large" && (
                <AppButton
                    buttonText={text || "Sign up for free"}
                    fontSize="text-md md:text-lg"
                    fontWeight="font-medium md:font-bold"
                    padding="p-1"
                    customClass={customClass}
                    ga={ga}
                    href={href || "https://app.gallabox.com/signup"}
                />
            )}
            {type === "outline" && (
                <a
                    className={`jsx-1957620388 no-underline hover:no-underline text-sm outline-none focus:outline-none border-solid border border-crayola-blue bg-white hover:bg-crayola-blue  text-crayola-blue hover:text-white rounded-md px-4 py-2 inline-flex items-center justify-center gap-1 transition duration-300 ease-in-out ${customClass}`}
                    href={href || "https://app.gallabox.com/signup"}
                >
                    <span className="text-sm tracking-wide font-medium text-lg">{text || "Start Building"}</span>
                    <span className="cta-btn-icon transition duration-300 ease-in-out">{outlineArr && <HiOutlineArrowNarrowRight className="text-2xl" />}</span>
                </a>
            )}
        </>
    );
};

export default CtaButton;
