import React, { FC } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { AppButtonProps } from "./types";

const AppButton: FC<AppButtonProps> = ({ buttonText, ga = true, showArrow = false, customClass, onClickAction, fontSize, fontWeight, padding, href }) => {
    return (
        <>
            {!href ? (
                <button
                    // className={`outline-none focus:outline-none w-full md:w-auto submit-btn whitespace-nowrap bg-crayola-blue text-white rounded-full px-6 py-3 inline-flex items-center justify-center md:justify-start gap-1 transition duration-300 ease-in-out ${customClass}`}
                    className={`${
                        ga ? "jsx-1957620388" : ""
                    } main-button outline-none focus:outline-none start-trial-btn hover:no-underline hover:shadow-lg transition duration-300 ease-in-out inline-flex items-center justify-center md:justify-start gap-1 ${customClass}`}
                    onClick={onClickAction}
                    type="submit"
                    aria-label={buttonText}
                >
                    <span className={`${fontSize ? fontSize : "text-sm"} ${fontWeight ? fontWeight : "font-medium"} ${padding ? padding : ""}`}>{buttonText}</span>
                    {showArrow && (
                        <span className="submit-btn-icon transition duration-300 ease-in-out">
                            <HiOutlineArrowNarrowRight className="text-2xl" />
                        </span>
                    )}
                </button>
            ) : (
                <a
                    href={href}
                    target="_blank"
                    rel="noreferrer"
                    className={`${
                        ga ? "jsx-1957620388" : ""
                    } main-button outline-none focus:outline-none start-trial-btn hover:no-underline hover:shadow-lg transition duration-300 ease-in-out inline-flex items-center justify-center md:justify-start gap-1 ${customClass}`}
                >
                    {" "}
                    <span className={`${fontSize ? fontSize : "text-sm"} ${fontWeight ? fontWeight : "font-medium"} ${padding ? padding : ""}`}>{buttonText}</span>
                    {showArrow && (
                        <span className="submit-btn-icon transition duration-300 ease-in-out">
                            <HiOutlineArrowNarrowRight className="text-2xl" />
                        </span>
                    )}
                </a>
            )}
        </>
    );
};

export default AppButton;
