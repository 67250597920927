import React, { FC } from "react";
import { ColoredTextProps } from "@components/Typography/types";

const ColoredText: FC<ColoredTextProps> = ({ coloredText, tag = "h1", customClass, fontSize }) => {
    const CustomTag: any = `${tag}`;
    const customStyles = {
        fontSize: fontSize,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return (
        <CustomTag style={customStyles} className={`app-colored-text ${customClass}`}>
            {coloredText}
        </CustomTag>
    );
};

export default ColoredText;
