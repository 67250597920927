import Modal from "@components/Modal/Modal";
import React from "react";

interface UseModalFormReturn {
    showForm: (header: string) => void;
    showModal: boolean;
    jsx: JSX.Element | null;
}

const useModalForm = (): UseModalFormReturn => {
    const [modalHeader, setModalHeader] = React.useState<string | null>(null);
    const [showModal, setShowModal] = React.useState<boolean>(false);

    const showForm = (header: string) => {
        setModalHeader(header);
        setShowModal(true);
    };
    const handleClose = () => {
        setModalHeader(null);
        setShowModal(false);
    };

    const jsx = showModal ? <Modal onClose={handleClose} modalHeader={modalHeader} ctaLabel={modalHeader} subjectSuffix={`WhatsApp Business [${modalHeader}]`} /> : null;
    return { showForm, showModal, jsx };
};

export default useModalForm;
